import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image'
import moment from 'moment';
import Layout from '../components/layout';
import SEO from '../components/seo';
import de from 'moment/locale/de';

moment.locale('de', de);

const BlogPostListTemplate = ({ data, pageContext }) => {
	const posts = data.allMarkdownRemark.edges;
	const { currentPage, numPages } = pageContext;
	// console.log('pageContext', pageContext);
	const isFirst = currentPage === 1;
	const isLast = currentPage === numPages;
	const prevPage = currentPage === 2 ? `/` : `/blog/page-${currentPage - 1}.html`;
	const nextPage = `/blog/page-${currentPage + 1}.html`;
	const types = {
		'youtube': 'video',
		'twitter': 'image',
		'instagram': 'image'
	};
	return (
		<Layout>
			<SEO title={!isFirst ? `Seite ${currentPage}` : null} keywords={[]} />
			<section id="articles">
				{posts.map((post) => {
					const { node } = post;
					const { fields, html } = node;
					let { title, image, date, type, youtubeid } = node.frontmatter;

					return (
						<React.Fragment key={node.id}>
							<span itemScope itemType="http://schema.org/Article">
								<section>
									<article className={types[type]}>
										{image && (
											<section>
												<Link to={fields.slug}>
													<Img fluid={image.childImageSharp.fluid} alt={title} />
												</Link>
											</section>
										)}
										{youtubeid && (
											<section>
												<div class="embed">
													<iframe title={youtubeid} loading="lazy" src={`https://www.youtube.com/embed/${youtubeid}?rel=0&amp;showinfo=0&amp;autoplay=1`}  frameborder="0" allowfullscreen></iframe>
												</div>
											</section>

										)}
										<hr />
										<footer>
											<h2>
												<Link to={fields.slug} itemProp="url">
													<span itemProp="name">{title}</span>
												</Link>
											</h2>
											<p
												itemProp="datePublished"
												content="moment.utc(page.date).format('YYYY-MM-DD')"
											>
												{moment.utc(date).format('DD. MMMM YYYY')}
											</p>
										</footer>
										{html ? <section dangerouslySetInnerHTML={{ __html: html }} /> : null}
									</article>
								</section>
							</span>
						</React.Fragment>
					);
				})}
			</section>
			<section id="pagination">
				<ul className="pager">
					{!isFirst && (
						<li className="previous">
							<Link to={prevPage} rel="prev">
								&larr; Neuer
							</Link>
						</li>
					)}

					{!isLast && (
						<li className="next">
							<Link to={nextPage} rel="next">
								Älter &rarr;
							</Link>
						</li>
					)}
				</ul>
			</section>
		</Layout>
	);
};
export default BlogPostListTemplate;

export const pageQuery = graphql`
	query blogListQuery($skip: Int!, $limit: Int!) {
		allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: $limit, skip: $skip) {
			edges {
				node {
					id
					html
					frontmatter {
						date
						path
						title
						image {
							publicURL
							childImageSharp {
								fluid(maxWidth: 460, quality: 90) {
									...GatsbyImageSharpFluid
								}
							}
						}
						type
						url,
						youtubeid
					}
					fields {
						slug
					}
				}
			}
		}
	}
`;
